<script setup lang="ts">
import { computed } from 'vue'

const props = withDefaults(
    defineProps<{
        checked?: string[] | boolean
        value?: string
    }>(),
    {
        checked: false,
        value: undefined,
    },
)

const emit = defineEmits<{ (e: 'update:checked', value: string[] | boolean): void }>()

const proxyChecked = computed({
    get(): string[] | boolean {
        return props.checked
    },

    set(value: string[] | boolean) {
        emit('update:checked', value)
    },
})
</script>

<template>
    <input
        v-model="proxyChecked"
        class="rounded-sm border-gray-300 text-pink-500 focus-within:z-10 focus:border-pink-500 focus:ring-pink-500"
        type="checkbox"
        :value="value"
    />
</template>
